import { mobileApplicationRoutePath } from '@bcf-v2/routes-landings/common/app/paths/mobile-application-route-path';
import { aboutUsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/about-us-route-path';
import { privacyPolicyRoutePath } from '@bcf-v2/routes-statics/common/app/paths/privacy-policy-route-path';
import { promotionsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/promotions-route-path';
import { responsibleGamingRoutePath } from '@bcf-v2/routes-statics/common/app/paths/responsible-gaming-route-path';
import { sitemapRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sitemap-route-path';
import { supportRoutePath } from '@bcf-v2/routes-statics/common/app/paths/support-route-path';
import { termsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/terms-route-path';
import { vipClubRoutePath } from '@bcf-v2/routes-statics/common/app/paths/vip-club-route-path';
import { cashoutRoutePath } from '@bcf-v2/routes-statics/sportsbook/app/paths/cashout-route-path';
import { betOnActionRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/bet-on-action-route-path';
import { bpBetBuilderRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/bp-bet-builder-route-path';
import { esportRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/esport-route-path';
import { liveBettingRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/live-betting-route-path';
import { playerBetsRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/player-bets-paths';
import { preMatchesRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/pre-matches-route-path';
import { virtualSportsRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/virtual-sports-route-path';
import { FooterLink } from '../types';

export const footerLinksFn = (): FooterLink[][] => [
  [
    {
      url: aboutUsRoutePath(),
      title: $localize`About us`
    },
    {
      url: 'https://lvbetpartners.com/',
      title: $localize`Affiliates`
    },
    {
      url: 'https://sports-blog.lvbet.lv/',
      title: $localize`Sports Blog`
    }
  ],
  [
    {
      url: preMatchesRoutePath(),
      title: $localize`Sports`
    },
    {
      url: liveBettingRoutePath(),
      title: $localize`Live betting`
    },
    {
      url: esportRoutePath(),
      title: $localize`Esport`
    },
    {
      url: promotionsRoutePath(),
      title: $localize`Bonuses`
    },
    {
      url: 'https://sports-stats.lvbet.lv/lv/soccer/events',
      title: $localize`Statistics`
    }
  ],
  [
    {
      url: bpBetBuilderRoutePath(),
      title: $localize`Betbuilder`
    },
    {
      url: virtualSportsRoutePath(),
      title: $localize`Virtual sports`
    },
    {
      url: playerBetsRoutePath(),
      title: $localize`Player Bets`,
      bottomSheetKind: 'dst-player-bets'
    },
    {
      url: betOnActionRoutePath(),
      title: 'LV Jackpot'
    },
    {
      url: mobileApplicationRoutePath(),
      title: $localize`Mobile application`
    },
    {
      url: cashoutRoutePath(),
      title: $localize`Cashout`
    },
    {
      url: vipClubRoutePath(),
      title: $localize`Vip Club`
    }
  ],
  [
    {
      url: supportRoutePath(),
      title: $localize`Support`
    },
    {
      url: termsRoutePath(),
      title: $localize`Terms and conditions`
    },
    {
      url: responsibleGamingRoutePath(),
      title: $localize`Responsible gaming`
    },
    {
      url: privacyPolicyRoutePath(),
      title: $localize`Privacy Policy`
    },
    {
      url: sitemapRoutePath(),
      title: $localize`Sitemap`
    }
  ]
];
